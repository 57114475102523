/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import ExternalLink from "../components/ExternalLink";
import SectionTitle from "../components/SectionTitle";
import PageContainer from "../components/PageContainer";
import Lightbox from "../components/Lightbox";

export const PressPageTemplate = ({ title, contactemail, pressclips }) => {
  return (
    <PageContainer>
      <SectionTitle title={title}>{title}</SectionTitle>
      <div sx={{ textAlign: "center", maxWidth: "500px", mx: "auto", mt: 3 }}>
        <ExternalLink url={`mailto:${contactemail}`} color={"#333"}>
          For press inqueries: {contactemail}
        </ExternalLink>
      </div>
      <div
        sx={{
          mb: 4,
          mt: 6,
          display: "grid",
          cursor: "pointer",
          gridTemplateColumns: [
            "repeat(2, 1fr)",
            "repeat(6, 1fr)",
            "repeat(8, 1fr)",
          ],
          gridGap: 4,
        }}
      >
        {pressclips?.map((pressclip, index) => (
          <Lightbox key={index} pressclip={pressclip} />
        ))}
      </div>
    </PageContainer>
  );
};

PressPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const PressPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <PressPageTemplate
        title={post.frontmatter.title}
        contactemail={post.frontmatter.contactemail}
        pressclips={post.frontmatter.pressclips}
      />
    </Layout>
  );
};

PressPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PressPage;

export const pressPageQuery = graphql`
  query PressPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "press-page" } }) {
      id
      frontmatter {
        title
        contactemail
        pressclips {
          alt
          featuredimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imagegallery {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
