/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { useState, Fragment } from "react";
import FsLightbox from "fslightbox-react";

const Lightbox = ({ pressclip }) => {
  const [toggler, setToggler] = useState(false);
  return (
    <Fragment>
      {pressclip.featuredimage && (
        <div
          onClick={() => setToggler(!toggler)}
          sx={{
            gridColumn: ["1 / span 2", "span 2"],
            overflow: "hidden",
          }}
        >
          <PreviewCompatibleImage
            imageInfo={{
              image: pressclip.featuredimage,
              alt: pressclip.alt,
            }}
          />
        </div>
      )}
      {pressclip?.imagegallery && (
        <FsLightbox
          toggler={toggler}
          sources={pressclip?.imagegallery.map((image) => {
            if (image.image) {
              return (
                <div
                  sx={{ height: "auto", width: "400px", minHeight: "500px" }}
                >
                  <img
                    sx={{ width: "100%" }}
                    key={image.alt}
                    src={image.image.childImageSharp.fluid.src}
                    alt={image.alt}
                  />
                </div>
              );
            } else {
              return <div></div>;
            }
          })}
        />
      )}
    </Fragment>
  );
};

export default Lightbox;

Lightbox.propTypes = {
  pressclip: PropTypes.object.isRequired,
};
